import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Frame, Layout, Loading, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, TextContainer, Banner, LegacyCard } from '@shopify/polaris';
import { TopBarMarkup, NavigationMarkup, CompaniesList } from '../../../../components';
import './CompaniesAll.scss';
import { useUser } from '../../../../utils/PrivateRoute';
import { Company } from '../../../../types';
import axios, { AxiosError } from 'axios';

export function CompaniesAll() {
  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);
  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  /** Loading markup */
  const loadingMarkup = isLoading ? <Loading /> : null;

  /** Company state */
  const [companies, setCompanies] = useState<Array<Company> | []>([]);

  /**
   * Fetch data
   */
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/companies', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setCompanies(data.data);
        } else {
          setError(true);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.error(axiosError);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCompanies();
  }, []);

  /**
   * Banner & toast
   */
  const bannerMarkup = error && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page
      title="Compagnie"
      primaryAction={
        <Button primary url="/admin/companies/new">
          Aggiungi compagnia
        </Button>
      }
    >
      <Layout>
        {/* Banner */}
        {bannerMarkup}

        <Layout.Section>
          <CompaniesList companies={companies} />
        </Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {pageMarkup}
    </Frame>
  );
}
