import { Button, Modal, LegacyStack, ChoiceList } from '@shopify/polaris';
import axios, { AxiosError } from 'axios';
import saveAs from 'file-saver';
import React, { useCallback, useEffect, useState } from 'react';

import { parseDateLabel } from '../../utils/Common';

interface Props {
  active: boolean;
  handleModalChange: any;
  organizationId: string;
  toggleToastSuccess: any;
  toggleBannerError: any;
  startDate: Date;
  endDate: Date;
}

type ReportChoice = {
  label: string;
  value: string;
};

export function PrintReportModal(props: Props) {
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [reportOptions, setReportOptions] = useState<ReportChoice[]>([{ label: 'Nessun report', value: 'no_report' }]);

  const exportOptions = [
    { label: 'Current page', value: 'current_page' },
    { label: 'All customers', value: 'all_customers' },
    { label: 'Selected customers', value: 'selected_customers' },
  ];

  const exportAsOptions = [
    // {
    //   label: 'CSV for Excel, Numbers, or other spreadsheet programs',
    //   value: 'csv_excel',
    // },
    {
      label: 'Excel',
      value: 'excel',
    },
  ];

  const [hasReports, setHasReports] = useState(false);
  const [selectedReport, setSelectedReport] = useState([]);
  const [selectedExport, setSelectedExport] = useState([]);
  const [selectedExportAs, setSelectedExportAs] = useState([]);

  const handleSelectedReport = useCallback((value: any) => setSelectedReport(value), []);
  const handleSelectedExport = useCallback((value: any) => setSelectedExport(value), []);
  const handleSelectedExportAs = useCallback((value: any) => setSelectedExportAs(value), []);

  // Handle modal close
  const handleClose = () => {
    props.handleModalChange();
    handleSelectedExport([]);
    handleSelectedExportAs([]);
  };

  /**
   * Fetch available reports for this organization
   */
  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await axios.get(
          (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/organizations/${props.organizationId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          },
        );
        const data = response.data;
        if (data.status === 'success') {
          const { organization } = data.data;
          const tmpReportOptions: ReportChoice[] = [];
          for (const product of organization.products) {
            if (product.reports && product.reports.length > 0) {
              for (const report of product.reports) {
                if (!tmpReportOptions.some((option) => option.value === report._id)) {
                  tmpReportOptions.push({ label: report.name, value: report._id });
                }
              }
            }
            // if (product.reports && !tmpReportOptions.some((option) => option.value === product.report._id)) {
            //   tmpReportOptions.push({ label: product.report.name, value: product.report._id });
            // }
          }
          // Add tmpReportOptions to reportOptions
          setReportOptions((prevState) => [...prevState, ...tmpReportOptions]);
          if (tmpReportOptions.length > 0) {
            setHasReports(true);
          }
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          console.log(axiosError.response.data);
        }
      }
    };
    fetchReports();
  }, [props.organizationId]);

  /**
   * Handle print report
   */
  const handlePrintReport = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.post(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/reports/print`,
        {
          report_id: selectedReport[0],
          export_as: selectedExportAs[0],
          date_start: props.startDate.toISOString(),
          date_end: props.endDate.toISOString(),
        },
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;
      // if (data.status === 'success') {
      //   handleClose();
      //   props.toggleToastSuccess();
      // }

      saveAs(data, `report_${parseDateLabel(new Date())}.xlsx`);
      handleClose();
      props.toggleToastSuccess();
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.log(axiosError.response);
        props.toggleBannerError(true);
      }
    } finally {
      setButtonSpinning(false);
    }
  }, [selectedReport, selectedExportAs, handleClose]);

  return (
    <Modal
      open={props.active}
      onClose={handleClose}
      title="Genera report"
      primaryAction={{
        content: 'Stampa',
        onAction: handlePrintReport,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <LegacyStack vertical>
          <p>
            Puoi selezionare uno tra i seguenti report attivi per la tua organizzazione, oppure puoi procedere selezionando gli elementi da esportare per il
            periodo indicato.
          </p>
          {/* Company reports */}
          {hasReports && (
            <LegacyStack.Item>
              <ChoiceList title="Report" choices={reportOptions} selected={selectedReport} onChange={handleSelectedReport} />
            </LegacyStack.Item>
          )}
          {/* Export */}
          {/* <LegacyStack.Item>
            <ChoiceList title="Esporta" choices={exportOptions} selected={selectedExport} onChange={handleSelectedExport} />
          </LegacyStack.Item> */}
          {/* Export as */}
          <LegacyStack.Item>
            <ChoiceList title="Esporta come" choices={exportAsOptions} selected={selectedExportAs} onChange={handleSelectedExportAs} />
          </LegacyStack.Item>
        </LegacyStack>
      </Modal.Section>
    </Modal>
  );
}
