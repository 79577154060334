/**
 * Modal to create a new quotation
 */
import { Banner, FormLayout, Modal, TextField } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { OrderMetadata, PricingRule, QuotationField, QuotationMetadata } from '../../types';

/**
 * Render quotation field markup
 */
type Props = {
  metadataField: QuotationMetadata | undefined;
  name: string;
  type: string;
  resultModifierType: 'fixed_amount' | 'percentage' | 'pricing_table' | 'none';
  fieldValue: string;
  handleSubtotal: (value: string, resultModifierType: 'fixed_amount' | 'percentage' | 'pricing_table' | 'none', fieldValue: string) => void;
  handleMetadata: (value: string, name: string, type: 'text' | 'number' | 'currency') => void;
};
const QuotationFieldComponent = ({ metadataField, name, type, resultModifierType, fieldValue, handleSubtotal, handleMetadata }: Props) => {
  const [value, setValue] = useState(metadataField?.value || '');

  const handleCurrencyChange = useCallback((e: any) => {
    // Check if the price has 2 or more commas and remove the last one
    if (e.split(',').length > 2) {
      e = e.replace(/,(?=[^,]*$)/, '');
    }

    // Check if the price has 3 or more numbers after the comma and remove the last one
    if (e.split(',')[1] && e.split(',')[1].length > 2) {
      e = e.replace(/.$/, '');
    }

    // Check if there are letters or special characters and remove them
    if (e.match(/[^0-9,]/g)) {
      e = e.replace(/[^0-9,]/g, '');
    }

    // Add dot as thousand separator
    e = e.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    setValue(e);
    handleSubtotal(e.replace('.', ''), resultModifierType, fieldValue);
    handleMetadata(e.replace('.', '').replace(',', '.'), name, 'currency');
  }, []);

  const handleValueChange = useCallback((e: any) => {
    // Check if the price has 2 or more commas and remove the last one
    if (e.split(',').length > 2) {
      e = e.replace(/,(?=[^,]*$)/, '');
    }

    // Check if the price has 3 or more numbers after the comma and remove the last one
    if (e.split(',')[1] && e.split(',')[1].length > 2) {
      e = e.replace(/.$/, '');
    }

    // Check if there are letters or special characters and remove them
    if (e.match(/[^0-9,]/g)) {
      e = e.replace(/[^0-9,]/g, '');
    }

    // Add dot as thousand separator
    e = e.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    setValue(e);
    handleSubtotal(e.replace('.', ''), resultModifierType, fieldValue);
    handleMetadata(e.replace('.', '').replace(',', '.'), name, 'number');
  }, []);

  const handleTextChange = useCallback((e: any) => {
    setValue(e);
    handleMetadata(e, name, 'text');
  }, []);

  const markup = (
    <TextField
      autoComplete="off"
      label={name}
      type="currency"
      placeholder="0,00"
      value={value.toString()}
      onChange={handleCurrencyChange}
      min={0}
      step={0.01}
      prefix="EUR"
      suffix="€"
    />
  );

  if (type === 'number' || type === 'percentage') {
    return <TextField autoComplete="off" label={name} type="number" value={value.toString()} onChange={handleValueChange} />;
  }

  if (type === 'text') {
    return (
      <TextField
        autoComplete="off"
        label={name}
        type="text"
        helpText="Verrà visualizzato nel preventivo"
        value={value.toString()}
        onChange={handleTextChange}
      />
    );
  }

  return markup;
};

export const QuotationModal = (
  variantId: string,
  quotationFields: QuotationField[] | null,
  orderMetadata: OrderMetadata | undefined,
  pricing_rules: PricingRule[] | undefined,
  active: boolean,
  setActive: any,
  onSave: any,
  onMetadata: any,
) => {
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [existError, setExistError] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [quotationMetadata, setQuotationMetadata] = useState<QuotationMetadata[]>([]);

  // Set metadata
  useEffect(() => {
    const tmp: QuotationMetadata[] = [];

    quotationFields?.forEach((field) => {
      if (field.type === 'text') {
        tmp.push({
          label: field.name,
          value: '',
          type: 'text',
        });
      } else if (field.type === 'percentage' || field.type === 'number') {
        tmp.push({
          label: field.name,
          value: 0,
          type: 'number',
        });
      } else if (field.type === 'currency') {
        tmp.push({
          label: field.name,
          value: 0,
          type: 'currency',
        });
      }
    });

    setQuotationMetadata(tmp);
  }, [quotationFields, variantId]);

  /**
   * Handle subtotal
   * @param value
   * @param resultModifierType
   * @param fieldValue
   * @returns
   */
  const handleSubtotal = (value: string, resultModifierType: 'fixed_amount' | 'percentage' | 'pricing_table' | 'none', fieldValue: string) => {
    // Check if the price has a comma and replace it with a dot
    if (value.includes(',')) {
      value = value.replace(',', '.');
    }

    // Check if the price is a number
    if (!isNaN(parseFloat(value))) {
      let newValue = parseFloat(value);

      // Check if the price is a percentage
      if (resultModifierType === 'percentage') {
        newValue = (newValue / 100) * parseFloat(fieldValue.includes(',') ? fieldValue.replace(',', '.') : fieldValue);
      }

      // Check if the price is a fixed amount
      if (resultModifierType === 'fixed_amount') {
        newValue = newValue;
      }

      setSubtotal(subtotal + newValue);

      // Pricing rules
      if (pricing_rules) {
        pricing_rules.forEach((rule) => {
          if (rule.name === 'minimum_price') {
            if (rule.type === 'percentage') {
              setSubtotal(((subtotal + newValue) / 100) * Number(rule.value));
            } else if (rule.type === 'fixed_amount' && subtotal + newValue < Number(rule.value)) {
              setSubtotal(Number(rule.value));
            }
          }
        });
      }
    }
  };

  /**
   * Handle metadata
   */
  const handleMetadata = useCallback(
    (value: string, name: string, type: 'text' | 'number' | 'currency') => {
      const tmp = [...quotationMetadata];
      const index = tmp.findIndex((item) => item.label === name);
      tmp[index].value = value;
      tmp[index].type = type;
      setQuotationMetadata(tmp);
    },
    [quotationMetadata],
  );

  const renderQuotationFields = () => {
    if (quotationFields) {
      return quotationFields.map((field, index) => {
        return (
          <FormLayout.Group key={index}>
            <QuotationFieldComponent
              metadataField={orderMetadata?.fields[index]}
              name={field.name}
              type={field.type}
              resultModifierType={field.result_modifier_type || 'none'}
              fieldValue={field.result_modifier_value || '0'}
              handleSubtotal={handleSubtotal}
              handleMetadata={handleMetadata}
            />
          </FormLayout.Group>
        );
      });
    }
  };

  /**
   * Handle quotation creation
   */
  const handleSave = useCallback(() => {
    setButtonSpinning(true);
    onSave(subtotal);

    // Add quotation metadata
    const tmp = [...quotationMetadata];
    tmp.push({
      label: 'quotation',
      value: subtotal,
      type: 'currency',
    });

    // Check if the order metadata is already set and update it
    if (orderMetadata) {
      orderMetadata.fields = tmp;
    } else {
      orderMetadata = {
        product: variantId,
        fields: tmp,
      };
    }

    onMetadata(orderMetadata);
    setSubtotal(0);
    setButtonSpinning(false);
    setActive(false);
  }, [subtotal, quotationMetadata, orderMetadata, variantId]);

  /**
   * Error markups & toast
   */
  const saveErrorMarkup = saveError && (
    <Modal.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Modal.Section>
  );

  const existErrorMarkup = existError && (
    <Modal.Section>
      <Banner title="Esiste già un cliente associato a questo codice fiscale" status="critical" onDismiss={() => setExistError(false)}>
        <p>Si è pregati di controllare il codice fiscale se si desidera proseguire.</p>
      </Banner>
    </Modal.Section>
  );

  const modalMarkup = (
    <Modal
      open={active}
      onClose={() => setActive(!active)}
      title="Crea un nuovo preventivo"
      primaryAction={{
        content: 'Salva',
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: () => setActive(!active),
        },
      ]}
    >
      {/* Banner */}
      {saveErrorMarkup}
      {existErrorMarkup}
      <Modal.Section>
        <FormLayout>{renderQuotationFields()}</FormLayout>
      </Modal.Section>
    </Modal>
  );

  return modalMarkup;
};
