import React, { useEffect, useState } from 'react';

import { Banner, LegacyCard, Text, Layout, Page, Spinner, LegacyStack, Tooltip } from '@shopify/polaris';
import { BarChart, DataPoint, DataSeries, LineChart } from '@shopify/polaris-viz';

import { AnalyticsDatePicker } from '../../components';
import { useUser } from '../../utils/PrivateRoute';
import { parseDateLabelChart } from '../../utils/Common';

type AnalyticsAdminProps = {
  isLoading: boolean;
  setLoading: any;
};

export function AnalyticsAdmin({ isLoading, setLoading }: AnalyticsAdminProps) {
  /**
   * State
   */
  const { user } = useUser();
  const [customers, setCustomers] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [premioNetto, setPremioNetto] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [organizationsTypes, setOrganizationsTypes] = useState([]);

  /**
   * Data
   */

  const customersData: DataPoint[] = [];
  const customersDataMap = new Map<string, number | undefined>();
  customers.forEach((customer: any) => {
    const date_created = parseDateLabelChart(new Date(customer.date_created));
    if (customersDataMap.has(date_created)) {
      let tmp = customersDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      customersDataMap.set(date_created, tmp);
    } else {
      customersDataMap.set(date_created, 1);
    }
  });

  // Convert customersDataMap to customersData
  customersDataMap.forEach((value: number | undefined, key: string) => {
    customersData.push({
      key: key,
      value: value || null,
    });
  });

  const customersDataSerie: DataSeries[] = [
    {
      data: customersData,
      color: '#00FFAA',
      name: 'Clienti',
    },
  ];

  const totalCustomers = customersData.reduce((acc, dataPoint) => {
    return acc + (dataPoint.value || 0);
  }, 0);

  // Quotes
  const quotesData: DataPoint[] = [];
  const quotesDataMap = new Map<string, number | undefined>();
  quotes.forEach((quote: any) => {
    const date_created = parseDateLabelChart(new Date(quote.date_created));
    if (quotesDataMap.has(date_created)) {
      let tmp = quotesDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      quotesDataMap.set(date_created, tmp);
    } else {
      quotesDataMap.set(date_created, 1);
    }
  });

  // Convert quotesDataMap to quotesData
  quotesDataMap.forEach((value: number | undefined, key: string) => {
    quotesData.push({
      key: key,
      value: value || null,
    });
  });

  const quotesDataSerie: DataSeries[] = [
    {
      data: quotesData,
      color: '#00FFAA',
      name: 'Preventivi',
    },
  ];

  const totalQuotes = quotesData.reduce((acc, dataPoint) => {
    return acc + (dataPoint.value || 0);
  }, 0);

  // Policies
  const policiesData: DataPoint[] = [];
  const policiesDataMap = new Map<string, number | undefined>();
  policies.forEach((policy: any) => {
    const date_created = parseDateLabelChart(new Date(policy.date_created));
    if (policiesDataMap.has(date_created)) {
      let tmp = policiesDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      policiesDataMap.set(date_created, tmp);
    } else {
      policiesDataMap.set(date_created, 1);
    }
  });

  // Convert policiesDataMap to PoliciesData
  policiesDataMap.forEach((value: number | undefined, key: string) => {
    policiesData.push({
      key: key,
      value: value || null,
    });
  });

  const policiesDataSerie: DataSeries[] = [
    {
      data: policiesData,
      color: '#00FFAA',
      name: 'Polizze',
    },
  ];

  const totalPolicies = policiesData.reduce((acc, dataPoint) => {
    return acc + (dataPoint.value || 0);
  }, 0);

  // Premio
  const premioData: DataPoint[] = [];
  const premioDataMap = new Map<string, number | undefined>();
  premioNetto.forEach((premio: any) => {
    const date_created = parseDateLabelChart(new Date(premio.date_created));
    const totalPrice = premio.total_price;
    if (premioDataMap.has(date_created)) {
      let tmp = premioDataMap.get(date_created);
      // @ts-ignore
      tmp += totalPrice;
      premioDataMap.set(date_created, tmp);
    } else {
      premioDataMap.set(date_created, totalPrice);
    }
  });
  // Convert premioDataMap to premioData
  premioDataMap.forEach((value: number | undefined, key: string) => {
    premioData.push({
      key: key,
      value: value || null,
    });
  });

  const premioDataSerie: DataSeries[] = [
    {
      data: premioData,
      color: '#00FFAA',
      name: 'Premi',
    },
  ];

  const totalPremio = premioData.reduce((acc, dataPoint) => {
    return acc + (dataPoint.value || 0);
  }, 0);

  // Organizations
  const organizationsData: DataPoint[] = [];
  const organizationsDataMap = new Map<string, number | undefined>();
  organizations.forEach((organizations: any) => {
    const date_created = parseDateLabelChart(new Date(organizations.date_created));
    if (organizationsDataMap.has(date_created)) {
      let tmp = organizationsDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      organizationsDataMap.set(date_created, tmp);
    } else {
      organizationsDataMap.set(date_created, 1);
    }
  });

  // Convert organizationsDataMap to organizationsData
  organizationsDataMap.forEach((value: number | undefined, key: string) => {
    organizationsData.push({
      key: key,
      value: value || null,
    });
  });

  const organizationsDataSerie: DataSeries[] = [
    {
      data: organizationsData,
      color: '#00FFAA',
      name: 'Organizzazioni',
    },
  ];

  const totalOrganizations = organizationsData.reduce((acc, dataPoint) => {
    return acc + (dataPoint.value || 0);
  }, 0);

  const dataOrganizationsTypes = [
    {
      name: 'BCFM 2019',
      data: [
        {
          key: 'Partners',
          value: 3,
        },
        {
          key: 'Partners 2.0',
          value: 0,
        },
        {
          key: 'Affinity',
          value: 4,
        },
      ],
    },
  ];

  /**
   * Charts
   */

  // Customers
  const customerCharts = (
    <LegacyStack vertical>
      <Text variant="headingSm" as="p">
        Evoluzione nel tempo
      </Text>
      <LineChart data={customersDataSerie} showLegend={false} emptyStateText="Nessun cliente" />
    </LegacyStack>
  );

  // Quotes
  const quotesCharts = (
    <LegacyStack vertical>
      <Text variant="headingSm" as="p">
        Evoluzione nel tempo
      </Text>
      <LineChart data={quotesDataSerie} showLegend={false} emptyStateText="Nessun preventivo" />
    </LegacyStack>
  );

  // Policies
  const policiesCharts = (
    <LegacyStack vertical>
      <Text variant="headingSm" as="p">
        Evoluzione nel tempo
      </Text>
      <LineChart data={policiesDataSerie} showLegend={false} emptyStateText="Nessuna polizza" />
    </LegacyStack>
  );

  // Premi
  const premioCharts = (
    <LegacyStack vertical>
      <Text variant="headingSm" as="p">
        Evoluzione nel tempo
      </Text>
      <LineChart data={premioDataSerie} showLegend={false} emptyStateText="Nessun premio" />
    </LegacyStack>
  );

  // Organizations
  const organizationsCharts = (
    <LegacyStack vertical>
      <Text variant="headingSm" as="p">
        Evoluzione nel tempo
      </Text>
      <LineChart data={organizationsDataSerie} showLegend={false} emptyStateText="Nessuna organizzazione" />
    </LegacyStack>
  );

  const organizationsTypesCharts = (
    <LegacyStack vertical>
      <Text variant="headingSm" as="p">
        Tipo di organizzazioni
      </Text>
      <BarChart data={dataOrganizationsTypes} showLegend={false} emptyStateText="Nessuna organizzazione" />
    </LegacyStack>
  );

  // Pass function to DashboardDatePicker
  const handleAnalytics = (data: any) => {
    setCustomers(data.customers);
    setQuotes(data.quotes);
    setPolicies(data.policies);
    setPremioNetto(data.premioNetto);
    setOrganizations(data.organizations);
    setOrganizationsTypes(data.organizationsTypes);
  };

  //BAnner markups
  const [error, setError] = useState(false);
  const bannerMarkup = error && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  // const loadingMarkup = isLoading ? <Loading /> : null;
  useEffect(() => {
    if (customersData.length !== 0 || quotesData.length !== 0 || policiesData.length !== 0 || premioData.length !== 0 || organizationsData.length !== 0) {
      setLoading(false);
    }
  }, [customersDataMap, quotesData, policiesData, premioData, organizationsData]);
  /**
   * Page markup
   */
  //Page markup for Admin users
  const pageMarkupAdmin = (
    <Page fullWidth title="Dashboard">
      {/* Banners */}
      {bannerMarkup}
      <div className="dashboardHeader">
        <LegacyStack alignment="center">
          <AnalyticsDatePicker handleAnalytics={handleAnalytics} setLoading={setLoading} />
          <Text as="span" color="subdued">
            Scegli la data di inizio e fine per visualizzare i dati.
          </Text>
        </LegacyStack>
      </div>
      <div className="dashboardContent">
        {/* First col */}
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Active customers */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Numeri di clienti" dismissOnMouseOut preferredPosition="above">
                  <span className="cardTitle">Clienti</span>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <>
                    <Text variant="headingMd" as="p">
                      Totale dei clienti nel periodo selezionato
                    </Text>
                    <Text variant="headingLg" as="h3">
                      {totalCustomers}
                    </Text>
                  </>
                )}
                {customerCharts}
              </LegacyStack>
            </LegacyCard>
            {/* Quotes */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Numeri di preventivi fatte" dismissOnMouseOut preferredPosition="above">
                  <span className="cardTitle">Preventivi</span>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <>
                    <Text variant="headingMd" as="p">
                      Totale dei preventivi creati nel periodo selezionato
                    </Text>
                    <Text variant="headingLg" as="h3">
                      {totalQuotes}
                    </Text>
                  </>
                )}
                {quotesCharts}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
        {/* Second col*/}
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Active customers */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Numeri di polizze fatte" dismissOnMouseOut preferredPosition="above">
                  <span className="cardTitle">Polizze</span>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <>
                    <Text variant="headingMd" as="p">
                      Totale delle polizze create nel periodo selezionato
                    </Text>
                    <Text variant="headingLg" as="h3">
                      {totalPolicies}
                    </Text>
                  </>
                )}
                {policiesCharts}
              </LegacyStack>
            </LegacyCard>
            {/* Active customers */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Premi calcolati a partire dalla data impostata" dismissOnMouseOut preferredPosition="above">
                  <span className="cardTitle">Premi</span>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <>
                    <Text variant="headingMd" as="p">
                      Totale dei premi raccolti nel periodo selezionato
                    </Text>
                    <Text variant="headingLg" as="h3">
                      {Number(totalPremio).toFixed(2)} €
                    </Text>
                  </>
                )}
                {premioCharts}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
        {/* Third col*/}
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Organizzazioni */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Numeri di organizzazioni" dismissOnMouseOut preferredPosition="above">
                  <span className="cardTitle">Organizzazioni</span>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <>
                    <Text variant="headingMd" as="p">
                      Organizzazioni totali
                    </Text>
                    <Text variant="headingLg" as="h3">
                      {totalOrganizations}
                    </Text>
                  </>
                )}
                {organizationsCharts}
                {organizationsTypesCharts}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
      </div>
    </Page>
  );

  return pageMarkupAdmin;
}
