import { useState, useCallback, useRef } from 'react';
import {
  LegacyCard,
  ContextualSaveBar,
  FormLayout,
  Frame,
  Layout,
  Page,
  LegacyStack,
  TextField,
  Banner,
  Toast,
  DropZone,
  Thumbnail,
  Select,
} from '@shopify/polaris';
import { TopBarMarkup, NavigationMarkup, contextControlMarkup } from '../../../../components';
import './CompaniesNew.scss';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../../utils/PrivateRoute';
import axios, { AxiosError } from 'axios';
import { Company, CompanyFile } from '../../../../types';

export function CompaniesNew() {
  const { user } = useUser();
  const navigate = useNavigate();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [error, setError] = useState(false);
  const [active, setActive] = useState(false);
  const [exist, setExist] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [company, setCompany] = useState<Company>({
    name: '',
    referent: {
      name: '',
      lastname: '',
      email: '',
      phone: '',
    },
    billing: {
      email: '',
      day: 1,
    },
    platform_link: '',
    phone: '',
    logo: undefined,
  });

  const [validationError, setValidationError] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  const handleDiscard = useCallback(() => {
    setCompany({
      name: '',
      referent: {
        name: '',
        lastname: '',
        email: '',
        phone: '',
      },
      billing: {
        email: '',
        day: 1,
      },
      platform_link: '',
      phone: '',
      logo: undefined,
    });
    setIsDirty(false);
  }, []);

  /** Company drop zone logo */
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    // Add necessary file properties for CompanyFile type
    const newLogo: CompanyFile = Object.assign(file, {
      key: file.name,
      title: file.name,
    });

    setCompany((prevCompany) => ({
      ...prevCompany,
      logo: newLogo,
    }));
    setIsDirty(true);
  }, []);

  const uploadedFile = company.logo?.key && (
    <LegacyStack alignment="center">
      <Thumbnail size="large" alt={company.logo.title} source={process.env.REACT_APP_BLOB_IMAGES_URL + company.logo.key} />
      <div>{company.logo.title}</div>
    </LegacyStack>
  );

  const fileUpload = !company.logo?.key ? <DropZone.FileUpload /> : null;

  const logoDropZoneMarkup = (
    <DropZone onDrop={handleDropZoneDrop} allowMultiple={false} label="Logo">
      {uploadedFile}
      {fileUpload}
    </DropZone>
  );

  /** Handle input changes */
  const handleChange = (field: keyof Company | 'referent' | 'billing', value: any, subField?: string) => {
    setCompany((prevCompany) => {
      if (field === 'referent' && subField) {
        return { ...prevCompany, referent: { ...prevCompany.referent, [subField]: value } };
      } else if (field === 'billing' && subField) {
        return { ...prevCompany, billing: { ...prevCompany.billing, [subField]: value } };
      } else {
        return { ...prevCompany, [field]: value };
      }
    });
    setIsDirty(true);
    setValidationError(false);
  };

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    if (!company.name || !company.billing?.email) {
      setValidationError(true);
      return;
    }

    try {
      setButtonSpinning(true);
      setError(false);
      setExist(false);

      // Create form data
      const formData = new FormData();
      if (company.logo) {
        formData.append('document', company.logo);
      }
      if (company.name) {
        formData.append('name', company.name);
      }
      if (company.referent) {
        formData.append('referent', JSON.stringify(company.referent));
      }
      if (company.billing) {
        formData.append('billing', JSON.stringify(company.billing));
      }
      if (company.platform_link) {
        formData.append('platform_link', company.platform_link);
      }
      if (company.phone) {
        formData.append('phone', company.phone);
      }

      console.log(formData);

      const response = await axios.post((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/companies/new`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      if (data.status === 'success') {
        setActive(true);
        setTimeout(() => {
          navigate(`/admin/companies`);
        }, 2000);
      } else if (data.status === 'company_exists') {
        setExist(true);
      } else {
        setError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setError(true);
    } finally {
      setButtonSpinning(false);
    }
    setIsDirty(false);
  }, [company, navigate]);

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      contextControl={contextControlMarkup}
    />
  ) : null;

  /**
   * Markups & error
   */
  const saveError = error && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const existError = exist && (
    <Layout.Section>
      <Banner title="Esiste già una compagnia con questo nome" status="critical" onDismiss={() => setExist(false)}>
        <p>Si è pregati di cambiare il nome se si desidera proseguire.</p>
      </Banner>
    </Layout.Section>
  );

  const validationErrorBanner = validationError && (
    <Layout.Section>
      <Banner title="Errore di convalida" status="critical" onDismiss={() => setValidationError(false)}>
        <p>I campi 'Nome' e 'Email di Fatturazione' sono obbligatori</p>
      </Banner>
    </Layout.Section>
  );

  const toastMarkup = active ? <Toast content="La compagnia è stata creata con successo." onDismiss={toggleActive} /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <Page title="Nuova compagnia" backAction={{ content: 'Compagnie', url: '/admin/companies' }}>
      <Layout>
        {/* Banner */}
        {saveError}
        {existError}
        {validationErrorBanner}
        {/* Panoramica compagnia */}
        <Layout.Section>
          <LegacyCard sectioned title="Panoramica compagnia">
            <FormLayout>
              <FormLayout.Group>
                <TextField label="Nome" value={company.name} onChange={(value) => handleChange('name', value)} autoComplete="off" />
              </FormLayout.Group>
              <FormLayout.Group>{logoDropZoneMarkup}</FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.Section>

        {/* Contatti e piattaforma */}
        <Layout.Section>
          <LegacyCard sectioned title="Contatti e Piattaforma">
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  label="Link alla Piattaforma"
                  value={company.platform_link}
                  onChange={(value) => handleChange('platform_link', value)}
                  autoComplete="off"
                />
                <TextField
                  label="Numero di telefono della compagnia"
                  value={company.phone}
                  onChange={(value) => handleChange('phone', value)}
                  autoComplete="off"
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.Section>

        {/* Dati Referente */}
        <Layout.Section>
          <LegacyCard sectioned title="Dati Referente">
            <FormLayout>
              <FormLayout.Group>
                <TextField label="Nome" value={company.referent?.name} onChange={(value) => handleChange('referent', value, 'name')} autoComplete="off" />
                <TextField
                  label="Cognome"
                  value={company.referent?.lastname}
                  onChange={(value) => handleChange('referent', value, 'lastname')}
                  autoComplete="off"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField label="Email" value={company.referent?.email} onChange={(value) => handleChange('referent', value, 'email')} autoComplete="off" />
                <TextField
                  label="Numero di Telefono"
                  value={company.referent?.phone}
                  onChange={(value) => handleChange('referent', value, 'phone')}
                  autoComplete="off"
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.Section>

        {/* Dati Fatturazione */}
        <Layout.Section secondary>
          <LegacyCard sectioned title="Dati Fatturazione">
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  label="Email di Fatturazione"
                  value={company.billing?.email}
                  onChange={(value) => handleChange('billing', value, 'email')}
                  autoComplete="off"
                />
                <Select
                  label="Giorno del Mese per la Fatturazione"
                  options={Array.from({ length: 31 }, (_, i) => ({
                    label: (i + 1).toString(),
                    value: (i + 1).toString(),
                  }))}
                  value={company.billing?.day.toString()}
                  onChange={(value) => handleChange('billing', parseInt(value), 'day')}
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.Section>
      </Layout>
      {contextualSaveBarMarkup}
      {toastMarkup}
    </Page>
  );

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {actualPageMarkup}
      {toastMarkup}
    </Frame>
  );
}
